import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'; // 라우터 추가하고 
import './styles/app.css'; // Here
import mitt from 'mitt'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const emitter = mitt();
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// Create Vue Instance
const app = createApp(App);
app.config.globalProperties.emitter = emitter;

//app.use(store);
app.use(router).use(pinia); // 사용 설정 하기

app.mount('#app');