import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';
// import { useRouter } from 'vue-router'
import { router } from '../router'

const baseUrl = `${process.env.VUE_APP_API_URL}/ballet`;

// const router = useRouter()

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(user_id, user_pwd) {
            
            const user = await fetchWrapper.post(`${baseUrl}/login`, { user_id, user_pwd });

            console.log(user)
            // update pinia state
            this.user = user;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            router.push('/admin')
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            router.push('/login')
        }
    }
});