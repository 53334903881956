// Vuex 때 처럼 create* 함수를 제공한다.
import { createWebHistory, createRouter } from "vue-router";
import { useAuthStore } from '@/store';

// const routes = [
//   {
//     path: "/",
//     name: "Home",
//     component: () => import("@/views/HomeView"), // 동적 import
//   },
//   {
//     path: "/login",
//     name: "Login",
//     component: () => import("@/views/LoginView"),
//   },
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     component: () => import("@/views/DashBoardView"),
//   },
//   {
//     path: "/directory",
//     name: "Directory",
//     component: () => import("@/views/DirectoryView"),
//   },
//   {
//     path: "/admin",
//     name: "Admin",
//     component: () => import("@/views/admin/AdminHome"),
//   },
// ];

const routes = [
  {
    path: "/",
    name: "defaultLayout",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/HomeView"), // 동적 import
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/admin/AdminLogin"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/DashBoardView"),
      },
      {
        path: "/directory",
        name: "Directory",
        component: () => import("@/views/DirectoryView"),
      },
    ],
  },
  {
    path: "/",
    name: "adminLayout",
    component: () => import("@/layouts/AdminLayout.vue"),
    children: [
      {
        path: "/admin",
        name: "Admin",
        component: () => import("@/views/admin/AdminHome"),
      },
      {
        path: "/admin/schedule",
        name: "AdminSchedule",
        component: () => import("@/views/admin/AdminSchedule"),
        meta: {
          title : '시간표'
        },
        
      },
      {
        path: "/admin/schedule/detail",
        name: "AdminScheduleDetail",
        component: () => import("@/views/admin/AdminScheduleDetail"),
        meta: {
          title : '시간표'
        },
      },
      {
        path: "/admin/student",
        name: "AdminStudent",
        component: () => import("@/views/admin/AdminStudent"),
        meta: {
          title : '학생관리'
        },
      },
      {
        path: "/admin/student/detail",
        name: "AdminStudentDetail",
        component: () => import("@/views/admin/AdminStudentDetail"),
        meta: {
          title : '학생관리'
        },
      },
      {
        path: "/admin/class",
        name: "AdminClass",
        component: () => import("@/views/admin/AdminClass"),
        meta: {
          title : '수업관리'
        },
      },
      {
        path: "/admin/class/edit",
        name: "AdminClassEdit",
        component: () => import("@/views/admin/AdminClassEdit"),
        meta: {
          title : '수업관리'
        },
      },
      {
        path: "/admin/teacher",
        name: "AdminTeacher",
        component: () => import("@/views/admin/AdminTeacher"),
        meta: {
          title : '강사관리'
        },
      },
      {
        path: "/admin/teacher/edit",
        name: "AdminTeacherEdit",
        component: () => import("@/views/admin/AdminTeacherEdit"),
        meta: {
          title : '강사관리'
        },
      },
      {
        path: "/admin/setting",
        name: "AdminSetting",
        component: () => import("@/views/admin/AdminSetting"),
        meta: {
          title : '문자발송'
        },
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    console.log('savedPosition : ', savedPosition)
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes,
}
 
);

router.afterEach((to, from) => {
  document.title  = to.meta.title === undefined ? '학원관리' : to.meta.title;

});

router.beforeEach((to, from, next) => {

  const authStore = useAuthStore();

  console.log(to, from)
  if(to.name.includes('Admin'))
  {
    if (authStore.user) {
      console.log(authStore.user)
      next();
    }
    else{
      return next({ path: "/login" });
    }
  }
  else {
    next();
  }
  
  
});

// router.beforeEach(async (to) => {
//     // redirect to login page if not logged in and trying to access a restricted page
//     // const publicPages = ['/login'];
//     // const authRequired = !publicPages.includes(to.path);
//     // const auth = useAuthStore();

//     // if (authRequired && !auth.user) {
//     //     auth.returnUrl = to.fullPath;
//     //     return '/login';
//     // }
  
// });